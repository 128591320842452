import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { List, Box, ListSubheader } from '@mui/material';
//
import { useState } from 'react';
import { NavListRoot } from './NavList';
import { ToFa } from '../../../utils/decimal';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  })
);

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function NavSectionVertical({ count, navConfig, isCollapse = false, ...other }) {
  const [expandedList, setExpandedList] = useState('');

  console.log(expandedList);

  return (
    <Box {...other}>
      {navConfig.map((group) => (
        <List key={group.subheader} disablePadding sx={{ px: 2 }}>
          <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                opacity: 0,
              }),
            }}
          >
            {group.subheader}
          </ListSubheaderStyle>

          {group.items.map((list) => (
            <div style={{ position: 'relative' }} key={list.title}>
              {list.badge && count > 0 && (
                <div
                  style={{
                    position: 'absolute',
                    top: -10,
                    left: 0,
                    width: 'auto',
                    height: 24,
                    background: '#FF0000',
                    color: 'white',
                    padding: '0 8px',
                    borderRadius: 8,
                    zIndex: 100,
                  }}
                >
                  {ToFa(count)}
                </div>
              )}
              <NavListRoot list={list} isCollapse={isCollapse} expandedList={expandedList} setExpandedList={setExpandedList} />
            </div>
          ))}
        </List>
      ))}
    </Box>
  );
}
